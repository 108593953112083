import React from 'react';
import s from './CartItemThumbnail.scss';
import {ItemLink} from '../CartItem/partials/ItemLink/ItemLink';
import classNames from 'classnames';
import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {ThumbnailImage} from 'wix-ui-tpa/cssVars';
import {getImageDimensions} from '../../../../domain/utils/itemUtilsWithSideEffect';
import {ProductType} from '@wix/wixstores-client-core';
import {Download, Document, GiftSmall} from '@wix/wix-ui-icons-common/on-stage';
import {ProductThumbnail} from '@wix/wixstores-client-common-components';
import {useControllerProps} from '../../../../domain/controllers/ControllerContext';

export enum CartItemThumbnailDataHook {
  Image = 'CartItemThumbnailDataHook.Image',
  DefaultImage = 'default-image',
  DefaultImageDigital = 'default-image-digital',
  DefaultImagePhysical = 'default-image-physical',
  Badge = 'product-badge',
  Wrapper = 'product-thumbnail-wrapper',
  Media = 'product-thumbnail-media',
}

const DEFAULT_IMAGE_DEFAULT_SIZE = 50;

export const CartItemThumbnail = ({lineItem, className}: {lineItem: LineItemModel; className?: string}) => {
  const imageDimensions = getImageDimensions(lineItem, CartItemThumbnail.defaultDimensions);
  const {useProductThumbnailWithWowImage, FixCustomLineItemImage} = useControllerProps().cartStore;

  const renderProductImageWithWowImage = () => {
    if (!lineItem.image) {
      return (
        <div
          data-hook={CartItemThumbnailDataHook.DefaultImage}
          className={classNames(s.defaultImage)}
          style={{
            width: imageDimensions.width,
            height: imageDimensions.height,
          }}>
          {lineItem.itemType === ProductType.DIGITAL ? (
            <Document
              size={DEFAULT_IMAGE_DEFAULT_SIZE.toString()}
              data-hook={CartItemThumbnailDataHook.DefaultImageDigital}
            />
          ) : (
            <GiftSmall
              size={DEFAULT_IMAGE_DEFAULT_SIZE.toString()}
              data-hook={CartItemThumbnailDataHook.DefaultImagePhysical}
            />
          )}
        </div>
      );
    }

    return (
      <div className={classNames(s.productImage)} data-hook={CartItemThumbnailDataHook.Wrapper}>
        <ThumbnailImage
          src={FixCustomLineItemImage ? lineItem.image.id ?? lineItem.image.url : lineItem.image.url}
          width={imageDimensions.width}
          height={imageDimensions.height}
          alt={lineItem.productName}
          data-hook={CartItemThumbnailDataHook.Media}
          aria-hidden={false}
        />
        {lineItem.itemType === ProductType.DIGITAL && (
          <div className={classNames(s.productBadge)} data-hook={CartItemThumbnailDataHook.Badge}>
            <Download />
          </div>
        )}
      </div>
    );
  };

  const renderProductImageFromLibrary = () => {
    const media = lineItem.image ? [{...lineItem.image, mediaType: 'PHOTO'}] : [];
    const product = {
      productType: lineItem.itemType,
      name: lineItem.productName,
      media,
    };

    return (
      <ProductThumbnail
        product={product}
        {...getImageDimensions(lineItem, CartItemThumbnail.defaultDimensions)}
        mountedToDOM={true}
      />
    );
  };

  return (
    <div className={classNames(s.thumbnail, className)} data-hook={CartItemThumbnailDataHook.Image}>
      <ItemLink lineItem={lineItem} tabIndex={-1}>
        {useProductThumbnailWithWowImage ? renderProductImageWithWowImage() : renderProductImageFromLibrary()}
      </ItemLink>
    </div>
  );
};

CartItemThumbnail.defaultDimensions = {width: 100, height: 100};
